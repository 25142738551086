import clsx from 'clsx'
import React, { MouseEventHandler, ReactElement, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import Icon from 'src/components/icons/icon'
import { useIsDemoAppMode } from 'src/utils/hooks/use-is-demo-app-mode'

import styles from './button.module.css'

export type ButtonProps = {
  /** Custom CSS Classes */
  className?: string
  /** onClick handler */
  onClick?: MouseEventHandler
  /** Text on the button */
  label?: string
  /** Element inside the button */
  children?: React.ReactNode
  /** Determines the style of the button * */
  kind?: 'primary' | 'secondary' | 'tertiary' | 'danger' | 'link' | 'anchor' | 'icon' | 'primaryGeneric'
  /** Custom style prop */
  style?: Record<string, any>
  /** if href provided to button, button will be a link */
  href?: string
  rel?: string
  /** target of href */
  target?: string
  /** name of icon */
  icon?: string | ReactElement | ReactNode | Array<string>
  /** Size of Icon. Takes number or pixel value. Example size={30} */
  iconSize?: string | number
  /** which side of the button to place the icon */
  iconPosition?: 'left' | 'right'
  /** Cg adustom Icon props */
  iconProps?: {
    className?: string
    size?: number
    icon?: string
    isSpinning?: boolean
  }
  /** Custom Inner content props */
  contentProps?: Record<string, any>
  /** Dark button theme */
  dark?: boolean
  /** Determines the size of the button */
  size?: 'small' | 'medium' | 'large'
  type?: 'button' | 'submit' | 'reset'
  disabled?: boolean
  to?: string | number
  form?: string
  id?: string
  name?: string
}

/**
 * Protip: Use `kind` prop to set styles of button
 */
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      onClick,
      label,
      children,
      className,
      kind: initialKind = 'primary',
      style,
      href,
      to,
      target,
      icon,
      iconSize = 20,
      iconPosition = 'left',
      iconProps = {},
      contentProps = {},
      dark,
      size = 'medium',
      type = 'button', // Default is 'submit' otherwise
      ...rest
    },
    ref,
  ) => {
    // If in demo app mode, use generic blue primary buttons
    let kind = initialKind
    const isDemoAppMode = useIsDemoAppMode()
    if (isDemoAppMode && kind === 'primary') {
      kind = 'primaryGeneric'
    }

    const text = kind === 'icon' ? children : label || children

    const classes = clsx(
      className,
      styles.button,
      styles[kind],
      dark ? styles.dark : '',
      styles[size],
      icon && styles.hasIcon,
      rest.disabled && styles.disabled,
    )

    let iconRender
    if (icon) {
      if (typeof icon === 'string') {
        // Icon is string use <use> icon
        const restProps = {
          ...iconProps,
          size: iconProps.size || iconSize,
          name: icon || (iconProps.icon as string),
          className: clsx(styles.icon, iconProps.className),
        }
        iconRender = <Icon {...restProps} />
      } else if (icon instanceof Array) {
        // Buttons with more than one icon
        const restProps = {
          ...iconProps,
          size: iconProps.size || iconSize,
          className: clsx(styles.icon, iconProps.className),
        }
        iconRender = icon.map((ele, i) => {
          return <Icon key={i} name={ele} {...restProps} />
        })
      } else {
        // Components
        iconRender = <div className='flex'>{icon}</div>
      }
    }

    const content = (
      <div className='between flex items-center justify-center gap-3'>
        {iconRender && iconPosition === 'left' ? iconRender : null}
        {text && (
          <div className={clsx(styles.buttonText)} {...contentProps}>
            {text}
          </div>
        )}
        {iconRender && iconPosition === 'right' ? iconRender : null}
      </div>
    )

    if (href) {
      return (
        <a
          className={classes}
          href={href}
          target={target}
          onClick={onClick}
          style={style}
          // @ts-ignore ignore problem with type
          ref={ref}
          {...rest}
        >
          {content}
        </a>
      )
    }

    if (to) {
      return (
        <Link
          className={classes}
          to={to as string}
          target={target}
          style={style}
          onClick={rest.disabled ? (e) => e.preventDefault() : onClick}
          aria-disabled={rest.disabled}
          // @ts-ignore ignore problem with type
          ref={ref}
          {...rest}
          title={label}
        >
          {content}
        </Link>
      )
    }

    return (
      <button className={classes} onClick={onClick} style={style} type={type} ref={ref} title={label} {...rest}>
        {content}
      </button>
    )
  },
)

Button.displayName = 'Button'

export default Button
